import React from 'react';
import { useState, useEffect } from 'react';

const EE = '/IB Extended Essay Muhammad Haaris Khan.pdf';

function ProjectsPage() {

	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

	useEffect(() => {
			document.title = 'Projects – Muhammad Haaris Khan';
		}, []);

	return (
		<div className='vertical-stack'>
			<div className='box blank wide'>
				<h1 className='title'>Projects</h1>
			</div>
			<div className='box white wide'>
				<h2 className='heading-on-white'><b>This Website</b>&nbsp; 2023</h2>
				<div className='horizontal-stack'>
					<span className='horizontal-blank small'></span>
					<span className='gold'></span>
					<span className='horizontal-blank small'></span>
				</div>
				<p className='text-on-white center-text'>
						This website was built entirely from scratch using React.js and CSS, without the use of any templates.<br/>
						It works on both desktop and mobile devices. This is my second project in React, 
						and this portfolio itself is being used both as a demonstration of skills and a learning experience.
						This website will be updated in the future to reflect new projects I have undertaken. Stay tuned!
				</p>
			</div>
			<div className='box-container reverse'>
				<div className='box blue right'>
					<div className='media-container'>
						<img className='image' src='/images/pose.png' />
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>Satellite Pose Estimation</b>&nbsp; 2024</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
							AI models for camera-based detection and tracking of satellites undergoing docking.<br/>
							Achieved satellite detection with 99.4% accuracy. Developed improved methods for satellite pose estimation. 
							Compared to previous methods, the new models are now 10× faster, 100× smaller (more power efficient), and can see twice as far.<br/>
							Improved accuracy and realism in Unreal Engine digital twin for creation of simulated datasets.<br/>
							Integrated units into full software-in-the-loop system for autonomous docking simulations.
						</p>
					</div>
				</div>
			</div>
			<div className='box-container'>
				<div className='box blue left'>
					<div className='media-container'>
						<video className='video' autoPlay loop muted>
							<source src="/videos/LiquidNoise.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<div className='horizontal-stack left'>
							<h2 className='heading-on-white'><b>Master’s Thesis</b>&nbsp; 2024&nbsp;&nbsp;&nbsp;</h2>
							<a href="https://github.com/Strikewind/LiquidNoise" target="_blank" rel="noopener noreferrer">
								<span><button className='button arrow'>VIEW REPO</button></span>
							</a>
						</div>
						<p className='subtitle-on-white'>
							Zero-shot Video using Image Diffusion Models
						</p>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
							Video generation models require enormous resources and pose environmental challenges. 
							This thesis takes a different approach to generating video by augmenting image diffusion models to support controllable and fast video 
							without any further training (zero-shot). The new methods, noise crystallization and liquid noise, can be implemented on top of
							already-existing image generators at no cost.
							The core concept has additional applications in relighting, seamless upscaling, and improved video style transfer with less distortions. 
							Findings of theoretical interest were also made, such as a method for human-interpretable, colour-corrected latent spaces.<br/>
							<b> A paper about these methods is also now under peer review at WACV conference.</b>
							
							
						</p>
					</div>
				</div>
			</div>
			<div className='box-container reverse'>
				<div className='box blue right'>
					<div className='media-container fix'>
						<video className='video fix' autoPlay loop muted>
							<source src="/videos/Phasefreqformattervideo.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>Phase Frequency Formatter</b>&nbsp; 2023</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
								A React/CSS web app which would help standardise and speed up the editing of documents 
								that contain information about rocket telemetry.<br/>
								Other ambitious ideas were implemented, such as quality of life features that hastened editing,
								and intuitive UI which leveraged familiarity biases of users, so their actions effortlessly 
								led to the results they expected.<br/>
								There also needed to be a large element of information design so that mistakes can be 
								picked up visually, rather than having to read text.<br/>
								Information not relevant at the time is hidden, so the user can always focus on what is important.<br/>
								This was my first project in React, and I was able to learn what was necessary to apply 
								it to a real-world problem.
						</p>
					</div>
				</div>
			</div>
			<div className='box-container'>
				<div className='box blue left'>
					<div className='media-container'>
						<video className='video' autoPlay loop muted>
							<source src="/videos/AVIO TLM.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>Avio Telemetry Suite</b>&nbsp; 2022</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
								This project consisted of replacing legacy software for Vega-C telemetry
								missionization with a new Python tool, reducing running time from half an hour to
								<b> 14 seconds</b>.<br/>
								I achieved these savings through the use of optimal data structures and algorithms,
								which also precluded obsolete processing and minimised cyclomatic complexity.<br/>
								The second part of the project was to create a PyQT GUI for the tool (as well as other
								AVIO telemetry tools), to allow non-programmers to interact with the suite.
								I incorporated ideas from information design to intuitively accommodate the needs
								of the user whilst hiding the complexity of the underlying programs.
						</p>
					</div>
				</div>
			</div>
			<div className='box white wide'>
				<div className='horizontal-stack'>
					<span><h2 className='heading-on-white'><b>IB Extended Essay</b>&nbsp; 2020&nbsp;&nbsp;</h2></span>
					<a href={EE} target="_blank" rel="noopener noreferrer">
						<span><button className='button arrow'>VIEW</button></span>
					</a>
				</div>
				<p className='subtitle-on-white'>
					Investigating the Effectiveness of Regularisation Techniques on Generative Adversarial Network Discriminator Overfitting
				</p>
				<div className='horizontal-stack'>
					<span className='horizontal-blank small'></span>
					<span className='gold'></span>
					<span className='horizontal-blank small'></span>
				</div>
				<p className='text-on-white center-text'>
						I conducted my high school final thesis on Generative Adversarial Networks (GANs), which included some original research.<br/>
						Invented in 2014, GANs are a structure of two symbiotic neural networks that, through vigorous competition, 
						lead to the self-improvement of the entire network.<br/>
						A common pitfall of GANs is discriminator overfitting. This problem arises from the fact that it's sometimes easier 
						to recognise something than create it. Hence, the generator half of the network falls behind the discriminator,
						and doesn't get a chance to learn anything useful. This paper focuses on methods to curtail discriminator progress (regularisation methods).
						To investigate their effectiveness, I have programmed a GAN that learns to
						plot mathematical functions, which utilises three methods (instance noise, label flipping, label smoothing) in varying degrees.
				</p>
			</div>
			<div className='box-container reverse'>
				<div className='box blue right'>
					<div className='media-container'>
						<img className='image' src='/images/raytracer.png' />
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>Ray Tracing Engine</b>&nbsp; 2023</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
								<b>*This image is now displayed on the walls of the Imperial EEE building.</b><br/>
								For my Graphics module coursework I made a ray tracing engine from scratch using WebGL.<br/>
								The ray tracing algorithm is compiled into GPU commands, which run for every pixel to be rendered. Using the input of a mouse direction vector,
								the algorithm bounces and refracts light rays using geometry intersection tests to 
								simulate the physics of light and determine what the final colour of the pixel will be.
								For extensions to the project, Monte Carlo path tracing was used to simulate roughness and light scattering. 
								Moreover, I implemented refraction and colour dispersion (splitting of colours) through glass, as well as basic caustics.<br/>
								This project was awarded winner of technical achievement with <b>99/100</b> marks!
						</p>
					</div>
				</div>
			</div>
			<div className='box-container'>
				<div className='box blue left'>
					<div className='media-container'>
						<img className='image' src='/images/FPGA2.png' />
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>Networked Multiplayer Game with FPGA controllers</b>&nbsp; 2022</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
								In a group for the Information Processing module, I coded the game software for my team's IoT system.
								I also participated in some aspects concerning database design, client-server communication (AWS), and hardware implementation in Quartus.<br/>
								The Maze Game is a multiplayer networked game which uses FPGA accelerometer controllers blasted with custom hardware. 
								The game itself runs with Pygame, and is object-oriented, meaning it can handle up to four players, a level editor, and run a bespoke particle system. 
								I programmed the particle engine from scratch to handle different types of flow, turbulence, lifetimes, etc. 
								I also created the physics engine for ball movement which uses SUVAT equations on component vectors of FPGA tilt, 
								as well as friction and restitution in order to simulate realistic ball movement and collisions.
						</p>
					</div>
				</div>
			</div>
			<div className='box-container reverse'>
				<div className='box blue right'>
					<div className='media-container'>
						<video className='video' autoPlay loop muted>
							<source src="/videos/SSS.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>Solar System Simulator</b>&nbsp; 2020</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
								For my IB Computer Science coursework, I programmed a software for a Physics teacher in order to help students learn 
								about the Solar System in an interactive way.<br/>
								The first feature is a clickable map of the Solar System which provides information on the bodies. 
								This is editable by text file which makes it easy for a non-programmer to update it as new science is revealed.<br/>
								The second feature is a long, scrollable map of the Solar System with the distances to scale (there are also buttons to fast travel), 
								which gives students an accurate sense of the scale which cannot be found in textbooks.<br/>
								The final feature is an animated numerical simulation of the three-body problem (Sun, planet, moon) where parameters such as mass, 
								gravitational constant, and distances can be tinkered with so the students get an intuitive feel for the orbital mechanics.
						</p>
					</div>
				</div>
			</div>
			<div className='box-container'>
				<div className='box blue left'>
					<div className='media-container'>
						<video className='video' autoPlay loop muted>
							<source src="/videos/ISSIE.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>Recursive Binary Partition Space Smart Wire Routing</b>&nbsp; 2023</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
								For the High Level Programming module, I worked on improving the smart wire autorouting feature in ISSIE (a computer hardware simulation tool) using F#.<br/>
								My novel solution is routing based on a binary partition space tree, which recursively works from the centre outwards, 
								allowing the wire to avoid obstacles in many arbitrarily complex scenarios. I also extended this with smart partial autorouting, 
								allowing the wire to route even after being manually manipulated by the user (promoting the natural, intuitive interaction from good UX).<br/>
								The use of F# for this project strengthened my functional programming fundamentals, and improved my understanding of functional abstraction, 
								the Hindley-Milner type system, and reduction of side-effects.
						</p>
					</div>
				</div>
			</div>
			<div className='box-container reverse'>
				<div className='box blue right'>
					<div className='media-container'>
						<img className='image' src='/images/Rover.png' />
					</div>
				</div>
				<div className='vertical-stack'>
					<div className='box white projects'>
						<h2 className='heading-on-white'><b>FPGA Real-time Computer Vision Pipeline</b>&nbsp; 2022</h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
								Hardware for a Mars rover camera system implemented in System Verilog and Quartus.<br/>
								Extremely efficient techniques were used to reduce convolution computations, such as: 
								separable filters, central limit theorem for Gaussians, and O(1) accumulation for moving averages.<br/>
								Spatial frequency gratings were used to find the best kernel size for edge detection in light of aliasing effects.<br/>
								White balance was implemented to utilise the full dynamic range for HSV calculations.
						</p>
					</div>
				</div>
			</div>
			<div className='multi-box-container'>
				<div className='box white shorter shrinkwrap'>
					<p className='subtitle-on-white'><b>LTspice-like Circuit Simulator</b> 2021</p>
					<p className='text-on-white'>Matrix methods implemented in Python. Accurate to LTspice within 10⁻¹⁰ V.</p>
					<div className='media-container small'>
						<img className='image' src='/images/LTSpice.jpg' />
					</div>
				</div>
				<div className='box white shorter shrinkwrap'>
					<p className='subtitle-on-white'><b>Detecting Brain Tumours in MRI Scans</b> 2023</p>
					<p className='text-on-white'>U-Net computer vision architecture using Pytorch.</p>
					<div className='media-container small'>
						<img className='image' src='/images/MRI.png' />
					</div>
				</div>
				<div className='box white shorter shrinkwrap'>
					<p className='subtitle-on-white'><b>Custom Machine Learning Library</b> 2022</p>
					<p className='text-on-white'>Low-level Python implementation of multi-layered neural networks trained using backpropagation.</p>
				</div>
				<div className='box white shorter shrinkwrap'>
					<p className='subtitle-on-white'><b>GAN for Generating CIFAR-10 Images</b> 2024</p>
					<p className='text-on-white'>Architecture built and trained using Pytorch.</p>
					<div className='media-container small'>
						<img className='image' src='/images/GAN.png' />
					</div>
				</div>
				<div className='box white shorter shrinkwrap'>
					<p className='subtitle-on-white'><b>C-to-MIPS Compiler</b> 2022</p>
					<p className='text-on-white'>Written in C++. Uses custom parser, as well as a lexer and code-gen.</p>
				</div>
				<div className='box white shorter shrinkwrap'>
					<p className='subtitle-on-white'><b>MIPS CPU</b> 2021</p>
					<p className='text-on-white'>CPU compatible with MIPS 1 ISA in System Verilog.</p>
				</div>
				<div className='box white shorter shrinkwrap'>
					<p className='subtitle-on-white'><b>Drone Control System</b> 2022</p>
					<p className='text-on-white'>Fine-tuned experimentally using MATLAB.</p>
				</div>
			</div>
		</div>
    );
}

export default ProjectsPage;