import React from 'react';
import { useState, useEffect } from 'react';

function EEPage() {

	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

  	useEffect(() => {
		document.title = 'Experience & Education – Muhammad Haaris Khan';
	}, []);

  	return (
		<div className='vertical-stack'>
			<div className='box blank wide'>
				<h1 className='title'>Experience</h1>
			</div>
			<div className='box white wide left-text'>
				<h2 className='heading-on-white'><b>Lúnasa Space Full-time</b> &nbsp;&nbsp; 2024 — Present</h2>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Computer Vision Engineer</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						AI models for camera-based detection and tracking of satellites undergoing docking.<br/>
						Achieved satellite detection with 99.4% accuracy. Developed improved methods for satellite pose estimation. 
						Compared to previous methods, the new models are now 10× faster, 100× smaller (more power efficient), and can see twice as far.<br/>
						Improved accuracy and realism in Unreal Engine digital twin for creation of simulated datasets.<br/>
						Integrated units into full software-in-the-loop system for autonomous docking simulations.
				</p>
			</div>
			<div className='box white wide left-text'>
				<h2 className='heading-on-white'><b>Avio 6-month Placement</b> &nbsp;&nbsp; 2023</h2>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Phase Frequency Formatter</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						A React/CSS web app which would help standardise and speed up the editing of documents that 
						contain information about rocket telemetry.<br/>
						To mitigate typographical errors, the tool automatically generated (from mouse clicks) the output 
						that the user previously had to type out themselves.<br/>
						Other ambitious ideas were implemented, such as quality of life features that hastened editing,
						and intuitive UI which leveraged familiarity biases of users, so their actions effortlessly 
						led to the results they expected.<br/>
						There also needed to be a large element of information design so that mistakes can be picked 
						up visually, rather than having to read text.<br/>
						I incorporated several ways to interact (ranging from clicking to direct typing) in order 
						to accommodate fast mass editing as well as precision editing.<br/>
						Information not relevant at the time is hidden, so the user can always focus on what is important.<br/>
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Power Distribution Optimisation Algorithm</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						The project was to make a python tool which automatically distributed components across units in order to stay under the power budget.<br/>
						To solve this, I created an algorithm using a combined cost function to steer the program to good layouts.<br/>
						Due to this being an NP-hard packing problem, a linear program was not suitable as it was not guaranteed to find the optimal solution.<br/>
						I also experimented with making a constraints solver, but there were memory issues with trying to evaluate 
						every feasible solution, when we only wanted one solution.<br/>
						A cost-based simulation approach led to 1-minute solutions as good as the manual ones that took hours to make.<br/>
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Statistical Analysis of Flight Anomalies</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						A flight anomaly needed to be investigated to find the root cause of signal loss. 
						Statistical analysis on flight trajectory data was conducted in order to find correlating variables, 
						making sure to mitigate the effects of confounding factors. Methods I explored include Bayesian probability, 
						cross-correlation and hypothesis testing. Careful data discretization and preprocessing was also done to ensure validity.
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Time-Sensitive Network Engineering</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						I participated in an investigation into the feasibility of using a new generation of networks called 
						Time-Sensitive Networking (TSN) in future launchers.<br/>
						Regular Ethernet with exponential backoff is not suitable for launchers due to non-deterministic behaviour.<br/>
						TSN is an adaptation to ethernet that specifies several extensions for synchronisation, 
						traffic scheduling, and reliability.<br/>
						My role in this project was to provide a set of requirements for TSN hardware procurement. 
						The requirements are also used in the system model 
						for the launch vehicle to aid in quality assurance testing and post-flight analysis. 
						I also evaluated and returned comments on other requirements for TSN written by consultants.
						Furthermore, I took part in hardware tests for candidate TSN chips, to find the optimal 
						settings for our needs and return feedback to the suppliers.
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Model-Based Systems Engineering</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						Model-based Systems Engineering (MBSE) is a method of system engineering requirements management.<br/>
						The concept is to integrate all system behaviour and requirements in a unified system model, 
						in order to discontinue the reliance on document-based systems engineering.<br/>
						MBSE can also integrate with sources from each domain (e.g. finite element models) to run 
						automated checks and serve as the central point of knowledge about the system. 
						MBSE is human readable and machine verifiable, which helps detect problems early.<br/>
						My role in this project was to learn and understand the concepts of MBSE in order to add to the launch vehicle model.
						This includes defining high-level black box requirements about the flight, to defining lower-level 
						white box subsystem requirements,
						and eventually individual unit requirements.<br/>
						During this, I also checked for any errors or discrepancies in the document-based specifications and reported it to the respective teams.<br/>
						Moreover, I sometimes needed to refactor existing requirements to fit with the model.<br/>
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Examining Effects of Particle Radiation on Electronics</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						Electronics in space are sensitive to particle radiation from a variety of sources. 
						Protons, electrons, and other ions originate from the Sun, Van Allen belts, and cosmic rays.<br/>
						These particles can cause Single Event Effects (SEE) in electronics, 
						which can cause a bit flip in memory, or even permanent damage.<br/>
						To mitigate this, electronics are designed to be radiation tolerant, and are tested to 
						ensure they meet the requirements.<br/>
						My role was to run simulations of particle radiation on models of electronics using the 
						planned trajectories of future launches, in order to define requirements to prevent worst-case scenarios.<br/>
				</p>
			</div>
			<div className='box white wide left-text'>
				<h2 className='heading-on-white'><b>Avio 3-month Internship</b> &nbsp;&nbsp; 2022</h2>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Vega-C Rocket Telemetry Automation</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						This project consisted of replacing legacy software for Vega-C telemetry
						missionization with a new Python tool, reducing running time from half an hour to
						14 seconds.<br/>
						I achieved these savings through the use of optimal data structures and algorithms,
						which also precluded obsolete processing and minimised cyclomatic complexity.
						The low complexity allowed for rigorous automated testing which verified
						correctness, consistency, and idempotency.<br/>
						Software design patterns and other best practices (e.g. loose coupling and high
						cohesion) were applied to mitigate technical debt and make the tool easily
						extendable.<br/>
						The tool had to comply with protocol specifications as well as user requirements.
						Technical, operational, and scheduling feasibility were also taken into
						consideration.
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Software Suite Development and UI Design</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						This task ran in parallel to the telemetry automation project.<br/>
						AVIO telemetry tools (including my automation tool) needed to be wrapped in a
						PyQt GUI to allow non-programmers to interact with the suite. This reduces
						training and onboarding time, as well as increasing user-friendliness and efficiency.<br/>
						I incorporated ideas from information design to intuitively accommodate the needs
						of the user whilst hiding the complexity of the underlying programs. The user can
						then ignore superfluous properties of the code, removing the need for
						documentation.<br/>
						Distractions are prevented by granting affordances and presenting only the most
						relevant aspects at the forefront at a time.<br/>
						Code-wise, the launcher and program interfaces are completely decoupled from
						the tool scripts, meaning functionality can be altered without compromising the UI.
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Signal Processing</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
						I simulated FIR/IIR filter chains to aid in post-flight analysis of sensor data. These results were used in collaboration with 
						simulations from other departments to reach a deeper understanding of the flight data. 
						The findings were then presented so that a consensus could be reached about the situation.
				</p>
			</div>

			<div className='box blank wide'>
				<h1 className='title'>Education</h1>
			</div>
			<div className='box white wide left-text'>
				<h2 className='heading-on-white'><b>Imperial College London</b> &nbsp;&nbsp; 2020–2024</h2>
					<p className='subtitle-on-white'>MEng Electronic and Information Engineering</p>
					<p className='subtitle-on-white'><b>79% overall—1ˢᵗ</b></p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Year 4</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
						<p className='text-on-white timeline nowrap'><b>83% Overall — Dean's List</b></p>
					</div>
				</div>
				<p className='text-on-white timeline'>
					<ul>
						<li>Mathematics for Machine Learning</li>
						<li>Digital Image Processing</li>
						<li>Topics in Large Dimensional Data Processing</li>
						<li>Machine Learning for Imaging</li>
						<li>Advanced Computer Graphics</li>
						<li>Deep Learning</li>
						<li><b>Thesis: Novel Methods for Zero-shot Video using Image Diffusion Models (Generative AI)</b></li>
					</ul>
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Year 3</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
						<p className='text-on-white timeline nowrap'><b>77% Overall</b></p>
					</div>
				</div>
				<p className='text-on-white timeline'>
					<ul>
						<li>Introduction to Machine Learning</li>
						<li>Computer Vision</li>
						<li>Computer Graphics</li>
						<li>High Level Programming</li>
						<li>Digital Signal Processing</li>
						<li>Maths for Signals and Systems</li>
						<li>Operations Research</li>
						<li>Philosophy of Mind (Imperial Horizons module)</li>
					</ul>
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Year 2</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
						<p className='text-on-white timeline nowrap'><b>75% Overall — Dean's List</b></p>
					</div>
				</div>
				<p className='text-on-white timeline'>
					<ul>
						<li>Instruction Architectures and Compilers</li>
						<li>Software Systems (Databases and Networks)</li>
						<li>Discrete Maths (Algorithms and Complexity)</li>
						<li>Information Processing</li>
						<li>Control Systems</li>
						<li>Communications</li>
						<li>Mars Rover Group Project</li>
					</ul>
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>Year 1</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
						<p className='text-on-white timeline nowrap'><b>68% Overall</b></p>
					</div>
				</div>
				<p className='text-on-white timeline'>
					<ul>
						<li>Mathematics</li>
						<li>Digital Electronics and Computer Architecture</li>
						<li>Programming</li>
						<li>Analysis and Design of Circuits</li>
						<li>Topics in Electrical Engineering</li>
						<li>LTspice-like Circuit Simulator Group Project</li>
					</ul>
				</p>
			</div>
			<div className='box white wide left-text'>
				<h2 className='heading-on-white'><b>Gems Wellington International School Dubai</b> &nbsp;&nbsp; 2011–2020</h2>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>International Baccalaureate (IB)</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
						<p className='text-on-white timeline nowrap'><b>44/45 IB points</b></p>
					</div>
				</div>
				<p className='text-on-white timeline'>
					<ul>
						<li>HL Mathematics&nbsp;&nbsp; 7</li>
						<li>HL Physics&nbsp;&nbsp; 7</li>
						<li>HL Computer Science&nbsp;&nbsp; 7</li>
						<li>SL Geography&nbsp;&nbsp; 7</li>
						<li>SL English (Lang-Lit)&nbsp;&nbsp; 7</li>
						<li>SL Spanish Ab Initio&nbsp;&nbsp; 6</li>
						<li>Extended Essay (CS)&nbsp;&nbsp; A</li>
						<li>Theory of Knowledge&nbsp;&nbsp; A</li>
					</ul>
				</p>
				<div className='horizontal-stack EE'>
					<div className='horizontal-span'>
						<p className='text-on-white timeline nowrap'><b>GCSEs</b></p>
						<span className='gold halfway'></span>
					</div>
					<div className='horizontal-blank'>
					</div>
				</div>
				<p className='text-on-white timeline'>
					<ul>
						<li>Computer Science&nbsp;&nbsp; A*</li>
						<li>Physics&nbsp;&nbsp; 9</li>
						<li>Chemistry&nbsp;&nbsp; 9</li>
						<li>Biology&nbsp;&nbsp; 9</li>
						<li>Astronomy&nbsp;&nbsp; A*</li>
						<li>Geography&nbsp;&nbsp; 9</li>
						<li>English Literature&nbsp;&nbsp; 9</li>
						<li>English Language&nbsp;&nbsp; 8</li>
						<li>Arabic&nbsp;&nbsp; A</li>
						<li>Mathematics&nbsp;&nbsp; A*</li>
						<li>Further Pure Mathematics&nbsp;&nbsp; B</li>
					</ul>
				</p>
			</div>
		</div>
	);
}

export default EEPage;